import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import _map from 'lodash/map';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';
import _get from 'lodash/get';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Sidebar from '../components/Sidebar';
import PostTile from '../components/PostTile';
import { cleanTitle, cleanExcerpt, formatDate } from '../utils/postUtils';

import styles from './blogList.module.scss';

const BlogCategoryList = props => {
  const { data, pageContext } = props;
  const { name, slug, currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '' : _toString(currentPage - 1);
  const nextPage = _toString(currentPage + 1);

  const posts = data.allWordpressPost.edges;
  return (
    <Layout>
      <SEO title={`${name} | Autism Parenting Journey`} pathname={`/blog/category/${slug}`} />
      <div className={styles.blogWrapper}>
        <div className={styles.postsWrapper}>
          <div className={styles.listHeader}>
            <div className={styles.sectionHeader}>Blog</div>
          </div>
          <div className={styles.categoryIdent}>
            Posts filed under category
            <span> {name}</span>
          </div>
          {_map(posts, ({ node }) => {
            const { id, slug: postSlug, title, content, date } = node;
            const author = _toLower(node.author.name);

            const fluidFeaturedImage = _get(
              node,
              'featured_media.localFile.childImageSharp.fluid',
              null,
            );

            return (
              <PostTile
                key={id}
                title={cleanTitle(title)}
                date={formatDate(date)}
                imageFluid={fluidFeaturedImage}
                author={author}
                excerpt={cleanExcerpt(content)}
                slug={postSlug}
                isOnBlogList
              />
            );
          })}
          <div className={styles.pageNavWrapper}>
            {!isFirst && (
              <Link to={`/blog/category/${slug}/${prevPage}`}>
                <div className={styles.pageNavLink}>&larr;</div>
              </Link>
            )}
            {!isLast && (
              <Link to={`/blog/category/${slug}/${nextPage}`}>
                <div className={styles.pageNavLink}>&rarr;</div>
              </Link>
            )}
          </div>
          <div className={styles.pageIdent}>
            Page {currentPage} of {numPages}
          </div>
        </div>
        <Sidebar />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query blogCategoryPageQuery($name: String!, $skip: Int!, $limit: Int!) {
    allWordpressPost(
      sort: { fields: [date], order: DESC }
      filter: { categories: { elemMatch: { name: { eq: $name } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          title
          content
          excerpt
          date
          modified
          author {
            name
          }
          categories {
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxHeight: 400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

BlogCategoryList.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default BlogCategoryList;
